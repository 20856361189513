import * as React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Terms = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <h1>Terms of Use</h1>
     
<p align="justify">
Jabones Artificiales welcomes you to Jabones.org.com. Below are our terms and conditions for use of the site, which you may access in several ways, including the World Wide Web, PDA, mobile phone, digital television, and RSS feeds. These terms and conditions apply whenever you access the site, on whatever devices.

<br/>
By using the site as a reader or registered user, you are deemed to have accepted these terms and conditions. If you are registered as a user, please read carefully the privacy policy.
<br/>
Any changes we make to the terms and conditions will be reflected on this page.
<br/>
<br/>
Access to and use of this site (Jabones.org.com) is provided by Jabones Artificiales subject to the following terms:
<br/>
<br/>
By using Jabones.org.com you agree to be legally bound by these terms, which shall take effect immediately on your first use of Jabones.org.com. If you do not agree to be legally bound by all the following terms please do not access and/or use Jabones.org.com.
Jabones Artificiales may change these terms at any time by posting changes online. Please review these terms regularly to ensure you are aware of any changes made by Jabones Artificiales. Your continued use of Jabones.org.com after changes to this policy are posted means you agree to be legally bound by these terms as updated and/or amended.
You may not copy, reproduce, republish, download, post, broadcast, transmit, make available to the public, or otherwise use Jabones.org.com content in any way except for your own personal, non-commercial use. You also agree not to adapt, alter or create a derivative work from any Jabones.org.com content except for your own personal, non-commercial use. Any other use of Jabones.org.com content requires the prior written permission of Jabones Artificiales.
You agree to use Jabones.org.com only for lawful purposes, and in a way that does not infringe the rights of, restrict or inhibit anyone else’s use and enjoyment of Jabones.org.com. Prohibited behaviour includes harassing or causing distress or inconvenience to any person, transmitting obscene or offensive content or disrupting the normal flow of dialogue within Jabones.org.com.


</p>

<br/>
DISCLAIMER OF LIABILITY
<p align="justify">
Jabones Artificiales does not accept any responsibility for any statement in the material. You must not rely on any statement we have published on Jabones.org.com without first taking specialist professional advice. Nothing in the material is provided for any specific purpose or at the request of any particular person.
<br/>
<br/>
We give no warranties of any kind and without any representations for material—information, names, images, pictures, logos and icons—found in Jabones.org.com.
<br/>
<br/>
You can access other sites via links from Jabones.org.com. These sites are not under our control and we are not responsible in any way for any of their contents.
<br/>
<br/>
Jabones Artificiales is not liable for any of the following losses or damages (whether such losses where foreseen, foreseeable, known or otherwise):
<br/><br/>
loss of data;
loss of revenue or anticipated profits;
loss of business;
loss of opportunity;
loss of goodwill or injury to reputation;
losses suffered by third parties; or
any indirect, consequential, special or exemplary damages arising from the use of Jabones.org.com regardless of the form of action.
You must take your own precaution in this respect, as we accept no responsibility for any infection by virus, by other contamination or by anything that has destructive properties.
<br/><br/>
IF YOU'RE UNDER 18:
Please get permission from a parent or guardian before taking part in any Jabones.org.com discussion.
Never reveal any personal information about yourself or anyone else (for example,telephone number, home address or email address).
THIRD PARTY MATERIAL ON Jabones.org.com
You will see advertising material submitted by third parties on Jabones.org.com. Advertisers are solely responsible for the content of advertising material, which they submit to us, including ensuring that it complies with relevant legislation. We accept no responsibility for the content of advertising material, including, without limitation, any error, omission or inaccuracy therein.
<br/><br/>
TEXT SUBMISSION TO Jabones.org.com
Users of our site may submit material for publication in various areas of the site, including our blog service. We accept no liability with respect to any material submitted by users and published by us and we are not responsible for its content and accuracy.
<br/><br/>
If you want to submit material to us for publication on Jabones.org.com, you may do so on the following terms and conditions:
<br/>
publication of any material you submit to us will be at your sole discretion. We reserve the right to make additions or deletions to the text or graphics prior to publication, or to refuse publication;
you grant us a non-exclusive, perpetual, royalty-free, worldwide licence to republish any material you submit to us in any format, including, without limitation, print and electronic format;
you declare to us that any material you submit to us is your own original work and that you own the copyright and any other relevant rights;
you warrant that the material you submit is not obscene, offensive, defamatory of any person or otherwise illegal;
you agree not to post material including spamming which is deliberately intended to upset other users;
you acknowledge that any breach of these warranties may cause us damage or loss and you agree to indemnify us in full and permanently against any third party liabilities, claims, costs, loss or damage we incur as a result of publishing material you submit to us, including consequential losses;
we reserve the right to remove your access to individual services if we believe you are abusing the services in any way.
GRAPHIC MATERIAL SUBMISSION TO Jabones.org.com
When you send a photograph or other graphical material to us you do so in accordance with these Terms of Use. This means that you hereby agree that you have taken the photograph(s) you have sent to us or you have permission from or are authorised by the owner of the photograph(s) to send it (them) to us, and you are granting us a non-exclusive, royalty-free licence to publish or otherwise use the photograph(s) in any way and at any time we want on Jabones.org.com. The photograph(s) must not be defamatory and may not break any laws.
<br/><br/>
Selected photographs and graphical material will be published at the discretion of the editor and you will not be paid, even if your photograph(s) is (are) published.

We may cut, edit, crop or arrange your photograph(s) or graphic as we think fit to appear on Jabones.org.com, and we may remove your photograph(s) or graphics at any time.

Your name will be published alongside your photograph(s) or graphic, but we may edit or delete any comments, which you submit along with your photograph(s) or graphic.
<br/><br/>
SAFETY
We advise that you never reveal any personal information about yourself or anyone else (for example: telephone number, home address or email address). Please do not include postal addresses of any kind. If you have a helpful address to share, inform the host of the relevant community area using the ‘Contact Us’ link, and they will promote it if they see fit.
<br/><br/>
GENERAL
These terms may vary from time to time. Please ensure that you review these terms and conditions regularly as you will be deemed to have accepted any variation if you continue to use the site after it has been posted.

Although we will do our best to provide constant, uninterrupted access to Jabones.org.com, we do not guarantee this. We accept no responsibility or liability for any interruption or delay.
<br/><br/>
GOVERNING LAW & JURISDICTION
These terms shall be governed by and interpreted in accordance with the laws of India and especially the Information Technology Act, 2000. All relevant rules, regulations, directions, orders and notifications will also apply.

By choosing to browse this site, you agree to the site's <Link to="/privacy">Privacy Policy</Link>

</p>

    </Layout>
  )
}

export const Head = () => <Seo title="Terms" />

export default Terms

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
